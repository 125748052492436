import {
  User,
  Message,
  Conversation,
  SessionKey,
  Application,
  FileDownload,
  ApplicationFull,
  Contact,
  KeyPair
} from './model';


const ResponsePong = "ResponsePong";
const ResponseStatusMessage = "ResponseStatusMessage";
const ResponseApplications = "ResponseApplications";
const ResponseApplicationsFull = "ResponseApplicationsFull";
const ResponseCreateConversation = "ResponseCreateConversation";
const NotificationAddMemberToConversation = "NotificationAddMemberToConversation";
const ResponseGetMembersList = "ResponseGetMembersList";
const ResponseGetConversations = "ResponseGetConversations";
const ResponseGetConversationsFull = "ResponseGetConversationsFull";
const ResponseGetPublicKeys = "ResponseGetPublicKeys";
const ResponseGetUpdatedPublicKeys = "ResponseGetUpdatedPublicKeys";
const ResponseSessionKeyByUuid = "ResponseSessionKeyByUuid";
const ResponseAddedSessionKey = "ResponseAddedSessionKey";
const ResponseAddedMessage = "ResponseAddedMessage";
const NotificationAddedMessageToConversation = "NotificationAddedMessageToConversation";
const ResponseGetMessages = "ResponseGetMessages";
const ResponseUserInfo = "ResponseUserInfo";
const ResponseFiles = "ResponseFiles";
const ResponseNicksList = "NicksList"
const NotificationNewSessionKey = "NotificationNewSessionKey";
const ResponseRegisteredUsers = "ResponseRegisteredUsers";
const NotificationNewPublicKey = "NotificationNewPublicKey";
const ResponseContactList = "ResponseContactList";
const NotificationConversationDeleted = "NotificationConversationDeleted";
const NotificationConversationLeaved = "NotificationConversationLeaved";
const NotificationTyping = "NotificationTyping";
const NotificationRenameConversation = "NotificationRenameConversation";
const NotificationUpdatedMessage = "UpdatedMessageNotification";
const NotificationAvatarChanged = "AvatarChangedNotification";
const NotificationConversationAvatarChangedNotification = "ConversationAvatarChangedNotification"
const ResponsePublicKey = "ResponsePublicKey"
const ResponseServerSettings = "ResponseServerSettings"
const NotificationConversationBlocked = "ConversationBlocked"

export interface ResponseObject {

}

export class StatusMessage implements ResponseObject {
  requestType: string = "";
  status: string = "";
  description: string = "";
}

export class ServerSettings implements ResponseObject {
  supportId: string = "";
  adminId: string = "";
  bigBrother: boolean = false;

  theme: Theme = new Theme()
}
export class Theme implements ResponseObject {
  buttonBackground: string = "#2e7d32";
  buttonText: string = "#FFFFFF";
  yourMessageBubbleBackground: string = "#c9c9f2";
  yourMessageBubbleText: string = "#000000";
  theirMessageBubbleBackground: string = "#ffffff";
  theirMessageBubbleText: string = "#000000";
}

export class UpdatedMessageNotification implements ResponseObject {

  uuid: string = "";
  conversationUuid: string = "";
  members: number = 0;
  readedMembers: number = 0;
  deliveredMembers: number = 0;
  objectionable: boolean = false;
  deleted: boolean = false;

}


export class UserInfo implements ResponseObject {
  user: User = new User("", "", "", new Date(),  new Date(), "", "");


}

export class PublicKey implements ResponseObject {
  key: KeyPair = new KeyPair();
}





export class  UserAvatarChangedNotification  implements ResponseObject {
  userId: string = "";
  avatarUpdated: Date = new Date();

}

export class Conversations implements ResponseObject {
  conversations: Conversation[] = [];

  lastMessages: Message[] = [];
  sessionKeys: SessionKey[] = [];


}



export class ConversationsFull implements ResponseObject {
  conversations: Conversation[] = [];

  lastMessages: Message[] = [];
  sessionKeys: SessionKey[] = [];
  firstUnreadMessageUuid: Message[] = [];


}

export class TypingNotification implements ResponseObject {
  conversationUuid: string = "";
  userId: string = "";


  constructor(conversationUuid: string = "", userId: string) {
    this.conversationUuid = conversationUuid;
    this.userId = userId;
  }
}


export class SessionKeyByUuid implements ResponseObject {
  sessionKey: SessionKey = new SessionKey();


}

export class Messages implements ResponseObject {
  messages: Message[] = [];


}

export class AddedMessage implements ResponseObject {
  message: Message = new Message("", "", "", "", "", "", new Date, false, false, "", false, "", "");

}


export class CreatedConversation implements ResponseObject {
  conversation: Conversation = new Conversation();

}

export class ConversationBlocked implements ResponseObject {
  conversation: Conversation = new Conversation();

}



export class MembersList implements ResponseObject {
  admins: string[] = [];
  members: string[] = [];


}


export class ContactList implements ResponseObject {
  users: Contact[] = [];


}

export class Pong implements ResponseObject {
  serverVersion: string = "";
  androidUrl: string = "";

}

export class AddedMessageToConversation implements ResponseObject {
  message: Message = new Message("", "", "", "", "", "", new Date, false, false, "", false, "", "");


}
export class AddedSessionKey implements ResponseObject {
  sessionKeyUuid: string = "";


}

export class NewPublicKey implements ResponseObject {
  userId: string = "";
  conversationUuid: string = "";
  keyBase64: string = "";


}


export class NicksList implements ResponseObject {
  nicks: Map<string,string> = new Map<string, string>();

}


export class UpdatedPublicKeys implements ResponseObject {
  publicKeys: Map<string,string> = new Map<string, string>();


}

export class NewSessionKey implements ResponseObject {
  sessionKey: SessionKey = new SessionKey();

  constructor(sessionKey: SessionKey) {
    this.sessionKey = sessionKey;
  }
}

export class ConversationDeleted implements ResponseObject {
  uuid: string = "";

}

export class ConversationLeaved implements ResponseObject {
  uuid: string = "";
  userId: string = "";


}

export class AddMemberToConversation implements ResponseObject {
  conversation: Conversation = new Conversation();
  userId: string = "";
}

export class RegisteredUsers implements ResponseObject {
  users: string[] = [];


}

export class Applications implements ResponseObject {
  applications: Application[] = [];


}

export class ApplicationsFull implements ResponseObject {
  applications: ApplicationFull[] = [];


}



export class Files implements ResponseObject {
  files:FileDownload[] = [];


}

export class RenameConversationNotification implements ResponseObject {
  conversationUuid: string = "";
  title: string = "";



}

export class ConversationAvatarChangedNotification implements ResponseObject {
  conversationUuid: string = "";
  avatarUpdated: Date = new Date();

}


export class Response {

  static parseJson(jsonString: string): [ResponseObject, number] {
    let stringArray = jsonString.split("\n");
    let command = stringArray[0].split(":")[0];
    let responseId: number = new Number(stringArray[0].split(":")[1]).valueOf();
    let object = JSON.parse(stringArray[1]);
    switch (command) {
      case ResponseStatusMessage: {
        let responseObject = new StatusMessage();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }
      case ResponseUserInfo: {
        if (object['user']['created'] != null) {
          object['user']['created'] = new Date(object['user']['created']);
        }
        if (object['user']['lastLogin'] != null) {
          object['user']['lastLogin'] = new Date(object['user']['lastLogin']);
        }
        let responseObject = new UserInfo();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }
      case ResponseGetConversations: {
        let responseObject = new Conversations();
        object["conversations"] as [any]
        object['conversations'] = (object["conversations"] as [any]).map(c => {
          let conversation: Conversation = new Conversation();
          object['lastMessages'] = (object['lastMessages'] as [any]).map(m => {
            let message: Message = new Message("", "", "", "", "", "", new Date, false, false, "", false, "", "");
            m['created'] = new Date(m['created']);
            Object.assign(message, m);
            return message;
          });
          object['sessionKeys'] = (object['sessionKeys'] as [any]).map(s => {
            s['created'] = new Date(s['created']);
            let sessionKey: SessionKey = new SessionKey();
            Object.assign(sessionKey,s);
            return sessionKey;

          });
          c['created'] = new Date(c['created']);
          if(c['lastMessage'] != null) {
            c['lastMessage'] = new Date(c['lastMessage']);
          } else {
            c['lastMessage'] = c['created'];
          }
          Object.assign(conversation, c);
          return conversation;
        });
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case ResponseGetConversationsFull: {
        let responseObject = new ConversationsFull();
        object["conversations"] as [any]
        object['conversations'] = (object["conversations"] as [any]).map(c => {
          let conversation: Conversation = new Conversation();
          object['lastMessages'] = (object['lastMessages'] as [any]).map(m => {
            let message: Message = new Message("", "", "", "", "", "", new Date, false, false, "", false, "", "");
            m['created'] = new Date(m['created']);
            Object.assign(message, m);
            return message;
          });

          object['firstUnreadMessageUuid'] = (object['firstUnreadMessageUuid'] as [any]).map(m => {
            let message: Message = new Message("", "", "", "", "", "", new Date, false, false, "", false, "", "");
            m['created'] = new Date(m['created']);
            Object.assign(message, m);
            return message;
          });

          object['sessionKeys'] = (object['sessionKeys'] as [any]).map(s => {
            s['created'] = new Date(s['created']);
            let sessionKey: SessionKey = new SessionKey();
            Object.assign(sessionKey,s);
            return sessionKey;

          });
          c['created'] = new Date(c['created']);
          if(c['lastMessage'] != null) {
            c['lastMessage'] = new Date(c['lastMessage']);
          } else {
            c['lastMessage'] = c['created'];
          }
          Object.assign(conversation, c);
          return conversation;
        });
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }


      case ResponseFiles: {
        let responseObject = new Files();
        object['files'] = (object["files"] as [any]).map(f => {
          let file: FileDownload = new FileDownload();
          Object.assign(file, f);
          return file;
        });
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }


      case ResponseApplications: {
        let responseObject = new Applications();
        object['applications'] = (object["applications"] as [any]).map(a => {
          let application: Application = new Application();
          a['created'] = new Date(a['created']);
          Object.assign(application, a);
          return application;
        });
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case ResponseApplicationsFull: {
        let responseObject = new ApplicationsFull();
        object['applications'] = (object["applications"] as [any]).map(a => {
          let application: ApplicationFull = new ApplicationFull();
          a['created'] = new Date(a['created']);
          a['lastLogin'] = new Date(a['lastLogin']);
          Object.assign(application, a);
          return application;
        });
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case ResponseSessionKeyByUuid: {
        let responseObject = new SessionKeyByUuid();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case ResponseServerSettings: {
        let responseObject = new ServerSettings();
        let theme = new Theme();
        Object.assign(theme, object['theme']);
        Object.assign(responseObject, object);
        responseObject.theme = theme;
        return [responseObject, responseId]
      }

      case ResponseAddedMessage: {
        let responseObject = new AddedMessage();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case ResponseGetUpdatedPublicKeys: {
        let responseObject = new UpdatedPublicKeys();
        let userIds: string[] = Object.getOwnPropertyNames(object['publicKeys']);
        userIds.forEach(userId => {
          responseObject.publicKeys.set(userId, object['publicKeys'][userId]);
        });
        return [responseObject, responseId]
      }

      case ResponseNicksList: {
        let responseObject = new NicksList();
        let userIds: string[] = Object.getOwnPropertyNames(object['nicks']);
        userIds.forEach(userId => {
          responseObject.nicks.set(userId, object['nicks'][userId]);
        });
        return [responseObject, responseId]
      }


      case ResponseGetMessages: {
        let responseObject = new Messages();

        object['messages'] = (object["messages"] as [any]).map(m => {
          let message: Message = new Message("", "", "", "", "", "", new Date, false, false, "", false, "", "");
          m['created'] = new Date(m['created']);
          Object.assign(message, m);
          return message;
        });

        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }


      case NotificationAvatarChanged: {
        let responseObject = new UserAvatarChangedNotification();
        object['avatarUpdated'] = new Date(object['avatarUpdated']);
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case NotificationConversationAvatarChangedNotification: {
        let responseObject = new ConversationAvatarChangedNotification();
        object['avatarUpdated'] = new Date(object['avatarUpdated']);
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case ResponseAddedSessionKey: {
        let responseObject = new AddedSessionKey();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case ResponsePublicKey: {
        let responseObject = new PublicKey();
        let key = new KeyPair();
        Object.assign(key, object['key']);
        object['key'] = key;
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }


      case NotificationRenameConversation: {
        let responseObject = new RenameConversationNotification();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }



      case NotificationNewSessionKey: {
        let sessionKey = new SessionKey();
        object['sessionKey']['created'] = new Date(object['sessionKey']['created']);
        Object.assign(sessionKey, object['sessionKey']);
        let responseObject = new NewSessionKey(sessionKey);
        return [responseObject, responseId]
      }


      case NotificationTyping: {
        let responseObject = new TypingNotification("", "");
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case ResponseCreateConversation: {
        let responseObject = new CreatedConversation();
        let conversation: Conversation = new Conversation();
        object['conversation']['created'] = new Date(object['conversation']['created']);
        object['conversation']['lastMessage'] = new Date(object['conversation']['lastMessage']);
        Object.assign(conversation, object['conversation']);
        object['conversation'] = conversation;
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case NotificationConversationBlocked: {
        let responseObject = new ConversationBlocked();
        let conversation: Conversation = new Conversation();
        object['conversation']['created'] = new Date(object['conversation']['created']);
        object['conversation']['lastMessage'] = new Date(object['conversation']['lastMessage']);
        Object.assign(conversation, object['conversation']);
        object['conversation'] = conversation;
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }



      case NotificationAddMemberToConversation: {
        let responseObject = new AddMemberToConversation();
        let conversation: Conversation = new Conversation();
        object['conversation']['created'] = new Date(object['conversation']['created']);
        object['conversation']['lastMessage'] = new Date(object['conversation']['lastMessage']);
        Object.assign(conversation, object['conversation']);
        object['conversation'] = conversation;
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case ResponseGetMembersList: {
        let responseObject = new MembersList();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case NotificationNewPublicKey: {
        let responseObject = new NewPublicKey();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }


      case ResponseContactList: {

        object['users'] = (object["users"] as [any]).map(c => {
          let contact: Contact = new Contact("", "", "", null);
          Object.assign(contact, c);
          return contact;
        });
        let responseObject = new ContactList();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case ResponseRegisteredUsers: {
        let responseObject = new RegisteredUsers();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case NotificationConversationDeleted: {
        let responseObject = new ConversationDeleted();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case NotificationConversationLeaved: {
        let responseObject = new ConversationLeaved();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }


      case NotificationAddedMessageToConversation: {

        if (object['message']['created'] != null) {
          object['message']['created'] = new Date(object['message']['created']);
        }

        let responseObject = new AddedMessageToConversation();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }

      case ResponsePong: {
        let responseObject = new Pong();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }
      case NotificationUpdatedMessage: {
        let responseObject = new UpdatedMessageNotification();
        Object.assign(responseObject, object);
        return [responseObject, responseId]
      }


      default: {
        console.log("Unknown response:" + command);
        return [{} as ResponseObject, 0];
      }
    }

  }

}
