<mat-sidenav-container class="mat-sidenav-container"  style="margin: auto; width: 1024px;height: 100vh;">
  <mat-sidenav mode="side" opened [fixedInViewport]="true">

    <div>

      <mat-toolbar>

        <mat-toolbar-row>
          <button mat-icon-button  (click)="goBack()">
            <mat-icon>clear</mat-icon>
          </button>
          <span style="flex: 1 1 auto;"></span>
          <button mat-icon-button     (click)="logout()">
            <mat-icon>lock</mat-icon>
          </button>
        </mat-toolbar-row>
        <mat-toolbar-row>
          &nbsp;&nbsp;<span>Search</span>
          <span style="flex: 1 1 auto;"></span>
          <span style="cursor: pointer;" (click)="selectTrigger()">{{selectAll}}</span>
        </mat-toolbar-row>

      </mat-toolbar>
      <div style="overflow-y: auto;">
        <mat-selection-list [multiple]="true" #selectedConversationsList>
          <mat-list-option *ngFor="let conversation of conversations"  [value]="conversation" [selected]="conversation.selected" (click)="click(conversation)">
            <img mat-list-avatar src="{{conversation.image}}">
            <div mat-line><span>{{conversation.title}}</span>&nbsp;<span class="text-end">{{conversation.lastMessage | date: 'd MMM'}}</span></div>
            <div mat-line> {{strip(conversation.lastMessageDisplay.body).substring(0, 30)}}
              <span [hidden]="conversation.unreadedCounter==0" matBadge="{{conversation.unreadedCounter}}" matBadgeOverlap="false"  matBadgeSize="small" style="position: relative; top:8px;left: 4px;"></span>
            </div>
          </mat-list-option>
        </mat-selection-list>



      </div>
    </div>






  </mat-sidenav>
  <mat-sidenav-content  style="overflow-y: hidden;"  [style.height.px]="windowInnerHeight-65">
    <mat-toolbar >


      <mat-form-field appearance="standard" style="width: 700px;">
        <mat-label>Type search string...</mat-label>
        <input matInput #input (keydown)="confirmSearchKeys($event)" [formControl]="searchStr">
      </mat-form-field>



    </mat-toolbar>

    <div  class="mesgs"  style="overflow-y: auto;"  [style.height.px]="windowInnerHeight-130"  #scrollMe [scrolldown]="true" >
      <div>


        <div *ngFor="let message of messages" style="padding-right: 10px;">
          <div  [hidden]="con.userId == message.userId" >
            <div style="padding-right: 300px; padding-left: 10px;  ">
              <mat-card style="margin: 0 0 4px;padding: 4px 12px 9px 14px;border-radius: 15px;word-break: break-all;white-space: pre-wrap;color:{{con.theme.theirMessageBubbleText}}; background-color: {{con.theme.theirMessageBubbleBackground}};">
                <span style="font-style: italic;">{{message.userNick}}</span><br>
                <span *ngFor="let file of message.files">
<!--                    <span *ngIf="getFileType(file.fileName) != 'wav'">{{file.fileName}}<br></span>-->
                  <fa-icon *ngIf="getFileType(file.fileName) == 'wav'" [icon]="faPlayCircle"
                           (click)="playAudio(file.body)"></fa-icon>
                  <fa-icon *ngIf="getFileType(file.fileName) == 'file'" [icon]="faFileDownload"
                           (click)="downloadFile(file, message)"></fa-icon>
                  <span *ngIf="getFileType(file.fileName) == 'file'">&nbsp;&nbsp;{{file.fileName}}</span>
                  <img *ngIf="getFileType(file.fileName) == 'jpeg' && (file.params.previewFileNameEncrypted == null || message.files.length == 1) && downloadImage(file, message)" [src]="file.body" (click)="file.hidden = false" (click)="viewFile(message, file)"/>
                  </span>
                <span  [innerHtml]="urlify(message.body)"></span>

              </mat-card>
            </div>
            <p align="right" style="padding-right: 300px; padding-left: 10px;">{{message.created | date: 'd MMM HH:mm'}}</p>
          </div>
          <div  [hidden]="con.userId != message.userId" >
            <div style="padding-left: 300px;">
              <mat-card style="color:{{con.theme.yourMessageBubbleText}}; background-color: {{con.theme.yourMessageBubbleBackground}}; margin: 0 0 4px;padding: 4px 9px 9px 14px;border-radius: 15px;white-space: pre-wrap;word-break: break-all;"  align="end" >
                  <span *ngFor="let file of message.files">
<!--                    <span *ngIf="getFileType(file.fileName) != 'wav'">{{file.fileName}}<br></span>-->
                  <fa-icon *ngIf="getFileType(file.fileName) == 'wav'" [icon]="faPlayCircle"
                           (click)="playAudio(file.body)"></fa-icon>
                  <fa-icon *ngIf="getFileType(file.fileName) == 'file'" [icon]="faFileDownload"
                           (click)="downloadFile(file,message)"></fa-icon>
                  <span *ngIf="getFileType(file.fileName) == 'file'">&nbsp;&nbsp;{{file.fileName}}</span>
                  <img *ngIf="getFileType(file.fileName) == 'jpeg' && (file.params.previewFileNameEncrypted == null || message.files.length == 1) && downloadImage(file, message)" [src]="file.body" (click)="file.hidden = false" (click)="viewFile(message, file)"/>
                  </span>
                <div class="message-from-me">
                  <span  [innerHtml]="urlify(message.body)"></span>
                  <div class="delivery-status">
                  </div>
                </div>
              </mat-card>
            </div>
            <p align="right">{{message.created | date: 'd MMM HH:mm'}}</p>
          </div>
        </div>





      </div>
    </div>




  </mat-sidenav-content>
</mat-sidenav-container>





