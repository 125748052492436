<mat-sidenav-container class="mat-sidenav-container"  style="margin: auto;" [style.width]="calcWidth()">
  <mat-sidenav mode="side" [opened]="openNav()" [fixedInViewport]="true" [style.height.px]="windowInnerHeight" [style.display]="displayNav()"  >

    <div [hidden]="!showContacts">

      <mat-toolbar>

        <mat-toolbar-row>
          <button mat-icon-button    (click)="showCreateConversation = false; hideConversations = false;showCreateGroup=false;selectedContactsDisplay = ''; showContacts = false">
            <mat-icon>clear</mat-icon>
          </button>
          <span style="flex: 1 1 auto;"></span>
          <button mat-icon-button     (click)="logout()">
            <mat-icon>lock</mat-icon>
          </button>
        </mat-toolbar-row>
        <mat-toolbar-row>
          &nbsp;&nbsp;<span>Contacts</span>
        </mat-toolbar-row>

      </mat-toolbar>
      <app-contacts></app-contacts>
    </div>


    <div [hidden]="!showCreateConversation">

      <mat-toolbar>

        <mat-toolbar-row>
          <button mat-icon-button    (click)="showCreateConversation = false; hideConversations = false;showCreateGroup=false;selectedContactsDisplay = ''">
            <mat-icon>clear</mat-icon>
          </button>
          <span style="flex: 1 1 auto;"></span>
          <button mat-icon-button     (click)="logout()">
            <mat-icon>lock</mat-icon>
          </button>
        </mat-toolbar-row>
        <mat-toolbar-row>
          &nbsp;&nbsp;<span [hidden]="showCreateGroup">Create chat</span> <span  [hidden]="!showCreateGroup">Create group chat

        </span>
          <span  style="flex: 1 1 auto;"></span>
          <span  [hidden]="!showCreateGroup">
          <button mat-icon-button  (click)="createChatGroup(selectedContactsList)" [disabled]="selectedContactsList.selectedOptions.selected.length ==0">
            <mat-icon>done</mat-icon>
          </button>
          </span>
        </mat-toolbar-row>

      </mat-toolbar>
      <div [hidden]="showCreateGroup">
      <mat-selection-list [multiple]="false">
        <mat-list-option (click)="showCreateGroup=true; groupCreateOption.selected=false;" [selected]="false" #groupCreateOption>
          <mat-icon mat-list-icon>groups</mat-icon>
          <div mat-line><span>&nbsp;&nbsp;&nbsp;Group chat</span></div>
        </mat-list-option>
      </mat-selection-list>


      <div style="overflow-y: auto;">
        <mat-selection-list [multiple]="false">
          <mat-list-option *ngFor="let contact of contacts" (click)="createDialog(contact)">
            <img mat-list-avatar [src]="contact.avatarSrc"  style="border-radius: 50%;" width="40" height="40">
            <div mat-line><span>{{contact.nickOpen}}</span></div>
          </mat-list-option>
        </mat-selection-list>
      </div>
      </div>


      <div [hidden]="!showCreateGroup">
        <mat-selection-list [multiple]="false">
          <mat-list-option>
            <div mat-line><span>&nbsp;&nbsp;&nbsp;Participants: {{selectedContactsDisplay}}</span></div>
          </mat-list-option>
        </mat-selection-list>
        <div style="overflow-y: auto;" >
          <mat-selection-list [multiple]="true" #selectedContactsList>
            <mat-list-option *ngFor="let contact of contactsWithoutSupport" (click)="selectContact(contact, selectedContactsList)" [value]="contact">
              <img mat-list-avatar [src]="contact.avatarSrc"  style="border-radius: 50%;" width="40" height="40">
              <div mat-line><span>{{contact.nickOpen}}</span></div>
            </mat-list-option>
          </mat-selection-list>

        </div>
      </div>


    </div>

    <div [hidden]="!showMenu || showSettings || showContacts">



      <mat-toolbar>

        <mat-toolbar-row>
          <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="showMenu=false">
            <mat-icon>arrow_back</mat-icon>
          </button>

          <span style="flex: 1 1 auto;"></span>

          <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"  (click)="logout()">
            <mat-icon>lock</mat-icon>
          </button>
        </mat-toolbar-row>
        <mat-toolbar-row>
          <img [src]="avatar"  style="border-radius: 50%;object-fit: cover;" width="40" height="40">
          &nbsp;&nbsp;<span>{{con.nickOpen}}</span>
        </mat-toolbar-row>

      </mat-toolbar>


      <mat-selection-list [multiple]="false">
        <mat-list-option (click)="search()">
          <div mat-line><span>Search</span></div>
        </mat-list-option>
        <mat-list-option (click)="showFingerprint()">
          <div mat-line><span>Fingerprint</span></div>
        </mat-list-option>
        <mat-list-option (click)="showContacts=true">
          <div mat-line><span>Contacts</span></div>
        </mat-list-option>
        <mat-list-option (click)="showSettings=true">
          <div mat-line><span>Settings</span></div>
        </mat-list-option>
        <mat-list-option (click)="showFaq(); showMenu=false">
          <div mat-line><span>FAQ</span></div>
        </mat-list-option>
        <mat-list-option (click)="logout()">
          <div mat-line><span>Logout</span></div>
        </mat-list-option>
      </mat-selection-list>




    </div>

    <div [hidden]="!showSettings">



      <mat-toolbar>

        <mat-toolbar-row>
          <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="showSettings=false">
            <mat-icon>arrow_back</mat-icon>
          </button>

          <span style="flex: 1 1 auto;"></span>

          <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="logout()">
            <mat-icon>lock</mat-icon>
          </button>
        </mat-toolbar-row>
        <mat-toolbar-row>
          &nbsp;&nbsp;<span>Settings</span>
        </mat-toolbar-row>

      </mat-toolbar>


      <app-settings></app-settings>




    </div>

    <div [hidden]="showMenu || showSettings || showCreateConversation">

      <mat-toolbar>
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="showMenu=true">
          <mat-icon>menu</mat-icon>
        </button>
        <span>Jetico Messenger <span style="font-size: 8px;">1.0</span></span>
      </mat-toolbar>

      <div style="overflow-y: auto;" [style.height.px]="windowInnerHeight-65">
        <mat-selection-list [multiple]="false">
          <mat-list-option *ngFor="let conversation of conversations"  (click)="selectConversation(conversation)" [selected]="conversation.selected">
            <img mat-list-avatar src="{{conversation.image}}">
            <div mat-line><pre>{{mysubstring(conversation.title)}} <span style="font-size: 10px;">{{conversation.lastMessage | date: 'd MMM'}}</span>
<span  style="font-size: 10px;">{{morestrip(conversation.lastMessageDisplay.body).substring(0, 30)}}</span><span *ngIf="conversation.unreadedCounter>0" matBadge="{{conversation.unreadedCounter}}" matBadgeOverlap="false"  matBadgeSize="small" style="position: relative; top:8px;left: 4px;"></span></pre>
            </div>
          </mat-list-option>
        </mat-selection-list>

      </div>


      <div class="inbox_people" [hidden]="hideConversations">
        <div class="headind_srch">
          <div class="recent_heading">

            <button (click)="createConversation()" mat-fab color="primary" aria-label="Example icon button with a delete icon" style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}};position: absolute; bottom: 30px;right: 30px;">
              <fa-icon [icon]="faEdit" size="lg"  ></fa-icon>
            </button>

          </div>
        </div>
      </div>


    </div>



  </mat-sidenav>
  <mat-sidenav-content  style="overflow-y: hidden;" [style.display]="displayMessages()" >
    <mat-toolbar [hidden]="conversations.length == 0 || hideMessages">

      <button mat-mini-fab color="primary" *ngIf="!openNav()" (click)="needOpenNav()"  aria-label="Example icon button with a menu icon">
        <mat-icon>arrow_back</mat-icon>
      </button>
          <img src="{{selectedConversation.image}}"  style="border-radius: 50%;object-fit: cover;" width="40" height="40"  *ngIf="selectedConversation.uuid!=''">
          &nbsp;
          <div style="display: inline-grid;">
            <span style="position:relative; top: 13px; left:5px; font-family: Roboto;font-size: 16px;font-weight: 500;font-stretch: normal;font-style: normal;line-height: normal;letter-spacing: normal;color: #454545;">{{selectedConversation.title}}</span>&nbsp;
            <span style="position:relative; top: -13px; left:5px; font-family: Roboto;font-size: 12px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.67;letter-spacing: normal;">
              {{selectedConversationMembers()}}
            </span>
          </div>


      <span style="flex: 1 1 auto;"></span>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" *ngIf="selectedConversation.uuid!=''">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="searchByUuid(selectedConversation.uuid)">
          <mat-icon>search</mat-icon>
          <span>Search</span>
        </button>
        <button mat-menu-item (click)="refreshKeys()">
          <mat-icon>vpn_key</mat-icon>
          <span>Re-generate session key</span>
        </button>
        <button mat-menu-item (click)="members()">
          <mat-icon>info</mat-icon>
          <span>Information: members list and etc</span>
        </button>
        <button mat-menu-item (click)="upload()" *ngIf="selectedConversation.isDialog == false  && isAdmin() == true">
          <mat-icon>file_upload</mat-icon>
          <span>Upload conversation avatar</span>
        </button>
        <div style="display: none;">
          <input id="click" class="form-control" type="file"  accept=".jpg,.jpeg"   (change)="onChange($event)">
        </div>
        <button mat-menu-item (click)="addUser()" *ngIf="!selectedConversation.isDialog && isAdmin() == true">
          <mat-icon>person_add</mat-icon>
          <span>Invite user to group chat</span>
        </button>

        <button mat-menu-item (click)="leave()">
          <mat-icon>arrow_forward</mat-icon>
          <span>Leave</span>
        </button>
        <button mat-menu-item (click)="delete()"  *ngIf="isAdmin() == true"  [hidden]="!selectedConversation.isAdmin">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>


      </mat-menu>

    </mat-toolbar>

    <div [hidden]="conversations.length == 0 || hideMessages" class="mesgs"  style="overflow-y: auto;" [style.height.px]="windowInnerHeight-130"  #scrollMe (scroll)="checkScroll(scrollMe.scrollTop, scrollMe)" [scrolldown]="scrollDown">
      <div>


          <div *ngFor="let message of messages" style="padding-right: 10px;">
            <div  [hidden]="con.userId == message.userId"  id="message_{{message.uuid}}">
              <div style=" padding-left: 10px;  " [style]="m11()">
                <mat-card [style]="m12()" style="border-radius: 15px;word-break: break-all;white-space: pre-wrap;color:{{con.theme.theirMessageBubbleText}}; background-color: {{con.theme.theirMessageBubbleBackground}};">
                  <span style="font-style: italic;">{{message.userNick}}</span><br>
                  <span *ngFor="let file of message.files">
<!--                    <span *ngIf="getFileType(file.fileName) != 'wav'">{{file.fileName}}<br></span>-->
                  <fa-icon *ngIf="getFileType(file.fileName) == 'wav'" [icon]="faPlayCircle"
                           (click)="playAudio(file, message)"></fa-icon>
                  <fa-icon *ngIf="getFileType(file.fileName) == 'file'" [icon]="faFileDownload"
                           (click)="downloadFile(file, message)"></fa-icon>
                  <span *ngIf="getFileType(file.fileName) == 'file'">&nbsp;&nbsp;{{file.fileName}}</span>
                  <img width="350"  *ngIf="getFileType(file.fileName) == 'jpeg' && (file.params.previewFileNameEncrypted == null || message.files.length == 1) && downloadImage(file, message)" [src]="file.body" (click)="file.hidden = false" (click)="viewFile(message, file)"/>
                  </span>
                  <span  [innerHtml]="urlify(message.body)"></span>

                </mat-card>
              </div>
              <p align="right" [style]="m11()" style=" padding-left: 10px;">{{message.created | date: 'd MMM HH:mm'}}</p>
            </div>
            <div  [hidden]="con.userId != message.userId" id="message_{{message.uuid}}">
              <div   [style]="m21()">
                <mat-card  [style]="m22()" style="color:{{con.theme.yourMessageBubbleText}}; background-color: {{con.theme.yourMessageBubbleBackground}}; margin: 0 0 4px;border-radius: 15px;white-space: pre-wrap;word-break: break-all;"  align="end" >
                  <span *ngFor="let file of message.files">
<!--                    <span *ngIf="getFileType(file.fileName) != 'wav'">{{file.fileName}}<br></span>-->
                  <fa-icon *ngIf="getFileType(file.fileName) == 'wav'" [icon]="faPlayCircle"
                           (click)="playAudio(file, message)"></fa-icon>
                  <fa-icon *ngIf="getFileType(file.fileName) == 'file'" [icon]="faFileDownload"
                           (click)="downloadFile(file,message)"></fa-icon>
                  <span *ngIf="getFileType(file.fileName) == 'file'">&nbsp;&nbsp;{{file.fileName}}</span>
                  <img width="350"  *ngIf="getFileType(file.fileName) == 'jpeg' && (file.params.previewFileNameEncrypted == null || message.files.length == 1) && downloadImage(file, message)" [src]="file.body" (click)="file.hidden = false" (click)="viewFile(message, file)"/>
                  </span>
                  <div class="message-from-me">
                    <span  [innerHtml]="urlify(message.body)"></span>
<!--                    {{message.readedMembers}} {{message.deliveredMembers}}-->

                    <div class="delivery-status">
                      <mat-icon class="icon-display" *ngIf="!(message.readedMembers == message.members && message.deliveredMembers == message.members) && !(message.readedMembers != message.members && message.deliveredMembers == message.members)">check</mat-icon>

                      <mat-icon class="icon-display" *ngIf="message.readedMembers != message.members && message.deliveredMembers == message.members">done_all</mat-icon>

                      <mat-icon class="icon-display green-icon" *ngIf="message.readedMembers == message.members && message.deliveredMembers == message.members">done_all</mat-icon>
                    </div>
                  </div>
                </mat-card>
              </div>
              <p align="right">{{message.created | date: 'd MMM HH:mm'}}</p>
            </div>
          </div>



        <div [hidden]="!sessionKeyAbsent" class="alert alert-danger" role="alert">
          Cant send message, Session Key need be regenerated. Click re-generate key button please.
        </div>

        <span  *ngFor="let t of typings">
            {{t}} typing... &nbsp;
          </span>

      </div>
    </div>

    <mat-toolbar style="position: absolute; bottom: 0px;">

      <mat-form-field appearance="outline" [style]="areaSize()" style="font-size: 12px; position: relative; top: 10px;"  [style.width.px]="windowInnerWidth - 600"  *ngIf="selectedConversation.uuid!=''">
        <textarea matInput (keydown)="keyDownFunction($event)"  [autofocus]="autofocus" [formControl]="message"  [style.resize]="'none'"></textarea>
      </mat-form-field>


      &nbsp;
      <button style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}};" *ngIf="selectedConversation.uuid!=''" mat-flat-button color="primary" (click)="sendMessage()"><fa-icon  size="lg" [icon]="faPaperPlane"></fa-icon></button>
      &nbsp;
      <button style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}};"   *ngIf="selectedConversation.uuid!=''" mat-flat-button color="primary" (click)="addImageModal()"><fa-icon  size="lg" [icon]="faImages"></fa-icon></button>
      &nbsp;
      <button style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}};"  mat-flat-button color="primary" *ngIf="record == false && selectedConversation.uuid!=''" (click)="recordAudio()"><fa-icon   size="lg" [icon]="faMicrophoneAlt"></fa-icon></button>
      <button style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}};"  mat-flat-button color="primary" *ngIf="record == true && selectedConversation.uuid!=''" (click)="stopAudio()"><fa-icon  size="lg" [icon]="faStop"></fa-icon></button>


    </mat-toolbar>

    <div class="type_msg" >
      <div class="input_msg_write">


      </div>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>





