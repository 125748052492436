import {
  User,
  Message,
  SessionKey,
  FileDownloadDisplay,
  FileParams,
  EventAdded,
  EventRemoved,
  EventSessionKeyRegenerated,
  DataEncryptedByMetaDataKey,
  DataEncryptedBySessionKey,
  ContactDisplay,
  ConversationDisplay,
  MessageDisplay, EventLeaved
} from "./model";
import {GetContactList, GetNicks, GetSessionKey, GetUserInfo, SessionKeyValue} from "./request";
import {
  ContactList,
  NicksList,
  RenameConversationNotification,
  ResponseObject,
  SessionKeyByUuid,
  StatusMessage,
  UserInfo
} from "./response";

import {v4 as uuidv4} from 'uuid';

import {Queue} from "./queue";


// @ts-ignore
import * as forge from 'node-forge'

import {MessangerConnectionService} from './messanger-connection.service';

export class Encryption {


  contactListQueue = new Queue<[string,(contact:ContactDisplay)=>void]>((element:[string,(contact: ContactDisplay)=>void] , done) => {

    let contactId: string = element[0];
    let f: (contact: any) => void =  element[1];

    if (this.contactList.has(contactId)) {
      f(this.contactList.get(contactId));
      done();

    } else {
      let requestGetContactList = new GetContactList(this.websocket.userId);
      this.websocket.sendRequest(requestGetContactList, (responseContactList) => {
        try {
          (responseContactList as ContactList).users.forEach(c => {
            let cd = new ContactDisplay("", "", "", "", "", "", null, "");
            cd.initBy(c, this);
            this.contactList.set(c.contactId, cd);
          });
          if (this.contactList.has(contactId)) {
            f(this.contactList.get(contactId));
            done();
          } else {
//          this.contactList.set(contactId, null);
            f(null);
            done();
          }

        } catch (e) {
          console.log(e)
          done();
        }

      });
    }
  });

   sessionKeyQueue = new Queue<[string,string,(sessionKeyUuid: string)=>void]>((element:[string,string,(sessionKeyUuid: string)=>void] , done) => {

     let conversationUuid: string = element[0];
     let sessionKeyUuid: string = element[1];
     let f: (sessionKeyEncrypted: any) => void =  element[2];

     try {
       if (this.sessionKeys.has(sessionKeyUuid)) {
         f(this.sessionKeys.get(sessionKeyUuid));
         done();

       } else {
         let sessionKey: SessionKey = this.websocket.storage.getSessionKey(sessionKeyUuid);
         if (sessionKey == null) {
           let requestGetSessionKey = new GetSessionKey(conversationUuid, sessionKeyUuid == null ? "" : sessionKeyUuid);
           this.websocket.sendRequest(requestGetSessionKey, (responseObject) => {
             let sessionKeyByUuid =responseObject as SessionKeyByUuid
             if(sessionKeyByUuid.sessionKey == null) {
               this.sessionKeys.set(sessionKeyUuid, null);
               f(null);
               done();
             } else {
               try {

                 let requestGetUserInfo = new GetUserInfo(  sessionKeyByUuid.sessionKey.signerUserId)
                 this.websocket.sendRequest(requestGetUserInfo,
                   (responseObject) => {
                     let responseUserInfo = responseObject as UserInfo
                     let fingerprintOpen = this.websocket.encryption.fingerprintByPublicKey(responseUserInfo.user.publicKeyBase64)
                     let requestGetContactList = new GetContactList(this.websocket.userId);
                     this.websocket.sendRequest(requestGetContactList, (responseContactList) => {
                      try {

                        let contacts = (responseContactList as ContactList).users
                        let contact = contacts.find(c => {
                          if(c.contactId == sessionKeyByUuid.sessionKey.signerUserId) {return true} else {return false}
                        })

                        if(contact != undefined && fingerprintOpen == this.websocket.encryption.decryptByMetaDataKey(contact.trustedFingerprintEncrypted)) {
                          let decryptedSessionKey = this.decryptRSA(sessionKeyByUuid.sessionKey.keyEncryptedBase64);
                          let ver = this.signatureBase64Check(decryptedSessionKey, sessionKeyByUuid.sessionKey.signatureBase64, responseUserInfo.user.publicKeyBase64)
                          if(ver) {
                            this.websocket.storage.saveSessionKey(sessionKeyByUuid.sessionKey);
                            this.sessionKeys.set(sessionKeyUuid, decryptedSessionKey);
                            f(decryptedSessionKey);
                            done();

                          } else {
                            this.sessionKeys.set(sessionKeyUuid, null);
                            f(null);
                            done();

                          }
                        } else {
                          // if(contact == undefined) {
                          //   let decryptedSessionKey = this.decryptRSA(sessionKeyByUuid.sessionKey.keyEncryptedBase64);
                          //   this.websocket.storage.saveSessionKey(sessionKeyByUuid.sessionKey);
                          //   this.sessionKeys.set(sessionKeyUuid, decryptedSessionKey);
                          //   f(decryptedSessionKey);
                          //   done();
                          // } else {
                          if(this.websocket.userId == sessionKeyByUuid.sessionKey.signerUserId) {
                            try {
                              let decryptedSessionKey = this.decryptRSA(sessionKeyByUuid.sessionKey.keyEncryptedBase64);
                              this.websocket.storage.saveSessionKey(sessionKeyByUuid.sessionKey);
                              this.sessionKeys.set(sessionKeyUuid, decryptedSessionKey);
                              f(decryptedSessionKey);
                              done();

                            } catch (e) {
                              // console.log(e)
                              this.sessionKeys.set(sessionKeyUuid, null);
                              f(null);
                              done();

                            }

                          } else {

                            let decryptedSessionKey = this.decryptRSA(sessionKeyByUuid.sessionKey.keyEncryptedBase64);
                            let ver = this.signatureBase64Check(decryptedSessionKey, sessionKeyByUuid.sessionKey.signatureBase64, responseUserInfo.user.publicKeyBase64)
                            if(ver) {
                              this.websocket.storage.saveSessionKey(sessionKeyByUuid.sessionKey);
                              this.sessionKeys.set(sessionKeyUuid, decryptedSessionKey);
                              f(decryptedSessionKey);
                              done();

                            } else {
                              this.sessionKeys.set(sessionKeyUuid, null);
                              f(null);
                              done();
                            }
                          }
                        }
                      } catch (e) {
                        f(null);
                        // console.log(e);
                        done();

                      }

                     })


                   })



               } catch (e) {
                 console.log(e);
                 this.sessionKeys.set(sessionKeyUuid, null);
                 f(null);
                 done();
               }
             }
           })
         } else {
           try {
             let decryptedSessionKey = this.decryptRSA(sessionKey.keyEncryptedBase64);
             this.sessionKeys.set(sessionKeyUuid, decryptedSessionKey);
             f(decryptedSessionKey);
             done();
           } catch (e) {
             console.log(e);
             this.sessionKeys.set(sessionKeyUuid, null);
             f(null);
             done();
           }
         }
       }

     } catch (e) {
       console.log(e)
       done();
     }


   });

  userNickQueue = new Queue<[string, string,(nick:string)=> void]>((element: [string, string,(nick:string)=> void], done)  => {
    let userId = element[0];
    let conversationUuid = element[1];
    let f = element[2];
    if(this.userNicks.has(userId + conversationUuid)) {
      f(this.userNicks.get(userId + conversationUuid));
      done();
    } else {

      if(userId == null) {
        f("UserId can not be NULL");
        done();
      } else {
        let requestNicksList = new GetNicks(conversationUuid);
        this.websocket.sendRequest(requestNicksList, (response) => {
          try {
            let responseNicksList: NicksList = <NicksList>response ;
            responseNicksList.nicks.forEach((nickEnc: string, uId: string) => {
              this.decryptBase64BySessionKey(nickEnc, (nick) => {
                this.userNicks.set(uId + conversationUuid, nick);
              });
            });
            setTimeout(() => {
              if(this.userNicks.has(userId + conversationUuid)) {
                f(this.userNicks.get(userId + conversationUuid));
              } else {

                this.getContact(userId, (contact) => {
                  if(contact != null) {
                    this.userNicks.set(userId + conversationUuid, contact.nickOpen);
                  }
                  if(this.userNicks.has(userId + conversationUuid)) {
                    f(this.userNicks.get(userId + conversationUuid));
                  } else {
                    this.userNicks.set(userId + conversationUuid, null);
                    f(userId);
                  }

                });
              }
            }, 1000);
          } catch (e) {
            console.log(e);
          }
          done();
        });
      }
    }
  });


  privateKey: any = null;
  publicKey: any = null;
  password: any = null;
  metaDataKey: any = null;

  privateKeyBase64: any = null;
  publicKeyBase64: any = null;

  contactList: any = new Map();
  sessionKeys: any = new Map();
  publicKeys: any = new Map();
  userNicks: any = new Map();

  timerFactory = this.websocket.timerFactory;

  generateUserKeys(userId: string, nick: string, password: string): User {
    this.password = password;

    let keypair = forge.pki.rsa.generateKeyPair({bits: 4096, e: 0x10001, workers: 4});

    this.privateKey = keypair.privateKey;
    this.publicKey = keypair.publicKey;

    let privateKeyBase64 = forge.pki.encryptRsaPrivateKey(keypair.privateKey, password);
    let publicKeyBase64 = forge.pki.publicKeyToPem(keypair.publicKey);

    this.publicKeyBase64 = publicKeyBase64
    this.privateKeyBase64 = privateKeyBase64

    this.metaDataKey = forge.random.getBytesSync(32);

    let user = new User(userId, publicKeyBase64, privateKeyBase64,  new Date(), new Date(), nick, this.getMetaDataKeyBase64());
    return user;
  }


  getMetaDataKeyBase64() {
    let keyEncryptedBase64 = this.encryptRSA(this.metaDataKey);
    return keyEncryptedBase64;
  }

  // addSessionKey(sessionKey: SessionKey) {
  //   try {
  //     let decryptedSessionKey = this.decryptRSA(sessionKey.keyEncryptedBase64);
  //     this.websocket.storage.saveSessionKey(sessionKey);
  //     this.sessionKeys.set(sessionKey.uuid, decryptedSessionKey);
  //   } catch (e) {
  //     console.log(e);
  //   }
  //
  // }

  restoreFrom(user: User, password: string): boolean {
    try {
      let privateKeyString = user.privateKeyEncryptedBase64;
      let publicKeyString = user.publicKeyBase64;
      this.privateKey = forge.pki.decryptRsaPrivateKey(privateKeyString, password);

      if (this.privateKey == null) {
        return false;
      }
      this.publicKey = forge.pki.publicKeyFromPem(publicKeyString);
      this.password = password;
      this.metaDataKey = this.decryptRSA(user.metaDataKeyEncryptedBase64);

      this.publicKeyBase64 = user.publicKeyBase64
      this.privateKeyBase64 = user.privateKeyEncryptedBase64
      return true;

    } catch (e) {
      console.log(e);
      return false;
    }

  }

  fingerprint(): string {
    return this.fingerprintByPublicKey(this.publicKeyBase64);
  }
  fingerprintByPublicKey(publicKey: string): string {
    let bytes = forge.util.decode64(publicKey.replace("-----BEGIN PUBLIC KEY-----", "").replace("-----END PUBLIC KEY-----",""))
    var md = forge.md.sha256.create();
    md.update(bytes);
    return forge.util.bytesToHex(md.digest().getBytes());
  }

  constructor(private websocket: MessangerConnectionService) {

  }

  decryptMessage(message: Message, f: (body: string) => void) {
    let timer = this.timerFactory.start("decryptMessage");
    this.getSessionKey(message.conversationUuid, message.sessionKeyUuid, (sessionKey) => {
      if(sessionKey == null) {
        timer.stop();
        f("Session Key is absent or invalid");
      } else {
            if(message.isEvent == false) {
              let decryptedBody = this.decryptAESUTF8(message.bodyEncryptedBase64, message.ivBase64, sessionKey);
              timer.stop();
              f(decryptedBody);
            } else {
              switch(message.eventType) {
                case EventAdded: {
                  this.getNick(message.eventUserId, message.conversationUuid, (nick) => {
                    timer.stop();

                    this.getContact(message.eventUserId, (contact) => {
                      if(contact != null) {
                        f("Added user " + nick + " " + contact.contactIdOpen);
                      } else {
                        if(this.websocket.userId != message.eventUserId) {
                          f("Added user " + nick + " " + message.eventUserId);
                        } else {
                          f("Added user me " + this.websocket.userIdOpen);
                        }
                      }
                    });
                  });
                  break;
                }
                case EventRemoved: {
                  this.getNick(message.eventUserId, message.conversationUuid, (nick) => {
                    timer.stop();
                    f("Removed user " + nick + " " + message.eventUserId);
                  });
                  break;
                }
                case EventSessionKeyRegenerated: {
                  timer.stop();
                  f("Session Key regenerated");
                  break;
                }
                case EventLeaved: {
                  timer.stop();
                  f("Left");
                  break;
                }
                default: {
                  timer.stop();
                  f(message.eventType);
                  break;
                }
              }
            }
      }
    })

  }



  decryptAvatarForConversation(dataEncryptedBySessionKey: DataEncryptedBySessionKey, c: ConversationDisplay, f: (retFile: any) => void) {

    this.getSessionKey(dataEncryptedBySessionKey.conversationUuid, dataEncryptedBySessionKey.sessionKeyUuid, (sessionKey) => {
      if(sessionKey == null) {
        f(null);
      } else {
        let decryptedBody = forge.util.encode64(this.decryptAESSync(forge.util.decodeUtf8(dataEncryptedBySessionKey.encryptedBodyBase64), dataEncryptedBySessionKey.ivBase64, sessionKey));
        f(decryptedBody);
      }
    })

  }

  decryptFileFull(message: MessageDisplay, file: FileDownloadDisplay, f: (retFile: FileDownloadDisplay) => void) {
    const start = new Date().getTime();
    // console.log('decryptFile start');

    this.getSessionKey(message.conversationUuid, message.sessionKeyUuid, (sessionKey) => {
      if(sessionKey == null) {
        f(file);
      } else {
        file.fileName = this.decryptAESUTF8(file.fileNameEncrypted, message.ivBase64, sessionKey);
        this.decryptAESAsync(file.bodyEncrypted, message.ivBase64, sessionKey, (decrypted) => {

          let decryptedBody = null;
          if(this.getFileType(file.fileName) == "wav") {
            // decryptedBody =new Blob([this.str2ab(decrypted)]);
            decryptedBody =new Blob([this.str2ab(decrypted)], {type: "octet/stream"});

          } else {
            if(this.getFileType(file.fileName) == "jpeg"){
              decryptedBody = "data:image/jpeg;base64," +  forge.util.encode64(decrypted);
            } else {

              if(this.getFileType(file.fileName) == "3gp") {
                // decryptedBody =  this.sanitizer.bypassSecurityTrustResourceUrl("data:video/3gp;base64," + forge.util.encode64(decrypted));
                // decryptedBody =  "data:video/3gp;base64," + forge.util.encode64(decrypted);
                decryptedBody =new Blob([this.str2ab(decrypted)], {type: "octet/stream"});

              } else {
                decryptedBody =new Blob([this.str2ab(decrypted)], {type: "octet/stream"});
              }
            }
          }
          file.body = decryptedBody;
          const finish = new Date().getTime();
          // console.log('decryptFile finished ' + (start - finish) + 'ms');
          f(file);

        });

      }
    })

  }

  decryptFileWithoutBody(message: MessageDisplay, file: FileDownloadDisplay, f: (retFile: FileDownloadDisplay) => void) {
    const start = new Date().getTime();
    // console.log('decryptFile start');

    this.getSessionKey(message.conversationUuid, message.sessionKeyUuid, (sessionKey) => {
      if(sessionKey == null) {
        f(file);
      } else {
        file.fileName = this.decryptAESUTF8(file.fileNameEncrypted, message.ivBase64, sessionKey);
        if(this.getFileType(file.fileName) == "jpeg" || this.getFileType(file.fileName) == "wav") {
          if(file.bodyEncrypted != "") {
            this.decryptAESAsync(file.bodyEncrypted, message.ivBase64, sessionKey, (decrypted) => {

              if(this.getFileType(file.fileName) == "jpeg"){
                file.body = "data:image/jpeg;base64," +  forge.util.encode64(decrypted);
              }
              if(this.getFileType(file.fileName) == "wav") {
                file.body =new Blob([this.str2ab(decrypted)]);
              }

              const finish = new Date().getTime();
              // console.log('decryptFile finished ' + (start - finish) + 'ms');
              f(file);

            });

          } else {
            f(file);
          }

        } else {
          f(file);

        }

      }
    })

  }

  str2ab(str: any):ArrayBuffer {
    var buf = new ArrayBuffer(str.length);
    var bufView = new Uint8Array(buf);
    for (var i=0, strLen=str.length; i<strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }


  encryptMessage(message: string, conversationUuid: string, sessionKeyUuid: string, userId: string, f: (m: Message) => void) {
    this.getSessionKey(conversationUuid, sessionKeyUuid, (sessionKey) => {
      try {
        let encryptedMessage = this.encryptAESUTF8(message, sessionKey, null);
        let ivBase64 = forge.util.encode64(encryptedMessage[0]);
        let bodyEncryptedBase64 = forge.util.encode64(encryptedMessage[1]);



        let m = new Message("", conversationUuid, sessionKeyUuid, userId, ivBase64, bodyEncryptedBase64, new Date(), false, false, "",false,"","");
        f(m);
      } catch (e) {
          alert("Cant send message, Session Key need be regenerated");
          f({} as Message);
      }
    })
  }

  encryptFile(file: File, param: FileParams, iv:any, conversationUuid: string, sessionKeyUuid: string, f: (fileDownloadDisplay: FileDownloadDisplay) => void) {
    this.getSessionKey(conversationUuid, sessionKeyUuid, (sessionKey) => {

      let enc = this;
      let reader = new FileReader();
      reader.onload = function () {

        let binary = this.result;

        try {
          let fileName = file.name;
          let encryptedFile = enc.encryptAES(binary, sessionKey, iv);
          let encryptedBase64 = forge.util.encode64(encryptedFile[1]);
          // let aa = enc.decryptAES(encryptedBase64, forge.util.encode64(iv), sessionKey);


          let encryptedFileName = enc.encryptAESUTF8(fileName, sessionKey, iv);
          let encryptedFileNameBase64 = forge.util.encode64(encryptedFileName[1]);
          let encryptedPreviewBase64 = null;
          if(param.previewFileNameEncrypted != null) {
            let encryptedPreview = enc.encryptAESUTF8(param.previewFileNameEncrypted, sessionKey, iv);
            encryptedPreviewBase64 = forge.util.encode64(encryptedPreview[1]);
          }

          param.previewFileNameEncrypted = encryptedPreviewBase64
          let fd = new FileDownloadDisplay("", "", "", "", true, param, "","");
          fd.bodyEncrypted = encryptedBase64;
          fd.fileNameEncrypted = encryptedFileNameBase64;
          f(fd);
        } catch (e) {
          console.log(e);
          alert("Cant add file, Session Key need be regenerated");
          f({} as FileDownloadDisplay);
        }

      };
      reader.readAsBinaryString(file);


    })
  }

  encryptAvatarByMetaDataKey(binary:any, ivBase64: string, f: (fileEncryptedByPublicKey: DataEncryptedByMetaDataKey) => void) {
      try {
        let sk = this.metaDataKey
        let binaryArrayBuffer = binary.arrayBuffer();
        binaryArrayBuffer.then((buffer: any) => {
          let encryptedFile = this.encryptAES(buffer, sk, forge.util.decode64(ivBase64));
          let encryptedBase64 = forge.util.encode64(encryptedFile[1]);
          let fd = new DataEncryptedByMetaDataKey(ivBase64, encryptedBase64);
          f(fd);
        });
      } catch (e) {
        console.log(e);
        f({} as DataEncryptedByMetaDataKey);
      }

  }
  encryptByMetaDataKeyImpl(binary:any) {
    try {
      let sk = this.metaDataKey
      let iv = forge.random.getBytesSync(16);
      let encryptedFile = this.encryptAES(binary, sk, iv);
      let encryptedBase64 = forge.util.encode64(encryptedFile[1]);
      let fd = new DataEncryptedByMetaDataKey( forge.util.encode64(iv), encryptedBase64);
      return forge.util.encode64(JSON.stringify(fd));
    } catch (e) {
      console.log(e);
      return null;
    }

  }

  decryptAvatarByMetaDataKey(str: string) {
    let dataEncryptedByPublicKey = new DataEncryptedByMetaDataKey( "", "");
    Object.assign(dataEncryptedByPublicKey,  JSON.parse(str));
    let decrypted = this.decryptAESSync(dataEncryptedByPublicKey.encryptedBodyBase64,dataEncryptedByPublicKey.ivBase64, this.metaDataKey);
    return forge.util.encode64(decrypted);
  }

  decryptAvatarByMetaDataKeyImpl(str: string) {
    let dataEncryptedByPublicKey = new DataEncryptedByMetaDataKey( "", "");
    Object.assign(dataEncryptedByPublicKey,  JSON.parse(str));
    let decrypted = this.decryptAESSync(dataEncryptedByPublicKey.encryptedBodyBase64,dataEncryptedByPublicKey.ivBase64, this.metaDataKey);
    return decrypted;
  }
  generateIV() {
    let iv = forge.random.getBytesSync(16);
    return forge.util.encode64(iv);
  }

  encryptAES(message: any, sessionKey: any, iv: any): [any, any] {
    const start = new Date().getTime();
    // console.log('encryptAES started');
    if (iv == null) {
      iv = forge.random.getBytesSync(16);
    }

    let cipher = forge.cipher.createCipher('AES-CTR', sessionKey);
    cipher.start({iv: iv});
    cipher.update(forge.util.createBuffer(message));
    cipher.finish();
    var encrypted = cipher.output.getBytes();
    // console.log(encrypted)
    // console.log(forge.util.encode64(encrypted));
    const finish = new Date().getTime();
    // console.log('encryptAES finished ' + (start - finish) + 'ms');
    return [iv, encrypted];

  }

  encryptAESUTF8(message: any, sessionKey: any, iv: any): [any, any] {
    return this.encryptAES(forge.util.encodeUtf8(message), sessionKey, iv)
  }


  public getSessionKey(conversationUuid: string, sessionKeyUuid: string, f: (sessionKeyEncrypted: any) => void) {

    this.sessionKeyQueue.push([conversationUuid, sessionKeyUuid, f]);


  }

  public getContact(contactId: string, f: (contact: ContactDisplay) => void) {

    this.contactListQueue.push([contactId, f]);


  }

  private decryptAESSync(encrypted: string, iv: string, key: any) {
    const start = new Date().getTime();
    // console.log('decryptAES started');
    var decipher = forge.cipher.createCipher('AES-CTR', key);
    decipher.start({iv: forge.util.decode64(iv)});

    var encryptedBytes = forge.util.decode64(encrypted);
    var length = encryptedBytes.length;
    var chunkSize = 1024 * 64;
    var index = 0;

    var decrypted = '';

    do {
      decrypted += decipher.output.getBytes();
      var buf = forge.util.createBuffer(encryptedBytes.substr(index, chunkSize));
      decipher.update(buf);
      index += chunkSize;
      const progress = new Date().getTime();
      // console.log('decryptAES progress ' + (start - progress) + 'ms');
    } while(index < length);
    var result = decipher.finish();
    decrypted += decipher.output.getBytes();

    const finish = new Date().getTime();
    // console.log('decryptAES finished ' + (start - finish) + 'ms');
    return decrypted;

  }

  private decryptAESPart( encryptedBytes: any, decrypted: any, decipher: any, index: any, length: any , f: (decrypted: any) => void) {


    var chunkSize = 1024 * 64;

    decrypted += decipher.output.getBytes();
    var buf = forge.util.createBuffer(encryptedBytes.substr(index, chunkSize));
    decipher.update(buf);
    index += chunkSize;
    const progress = new Date().getTime();
    // console.log('decryptAESAsync progress '  + (new Date().getTime()));
    var encThis = this;
     if (index < length) {
       setTimeout(()=> {
         encThis.decryptAESPart(encryptedBytes, decrypted, decipher, index, length, f);
         }, 10);

     } else {
       var result = decipher.finish();
       decrypted += decipher.output.getBytes();
       // console.log('decryptAESAsync finished ' + (new Date().getTime()));
       f(decrypted);
     }

  }

  private decryptAESAsync(encrypted: string, iv: string, key: any, f: (decrypted: any) => void) {
    const start = new Date().getTime();
    // console.log('decryptAESAsync started');
    var decipher = forge.cipher.createCipher('AES-CTR', key);
    decipher.start({iv: forge.util.decode64(iv)});

    var encryptedBytes = forge.util.decode64(encrypted);
    var length = encryptedBytes.length;
    var index = 0;

    var decrypted = '';

    this.decryptAESPart(encryptedBytes, decrypted, decipher, index, length, f);
    //
    // do {
    //   decrypted += decipher.output.getBytes();
    //   var buf = forge.util.createBuffer(encryptedBytes.substr(index, chunkSize));
    //   decipher.update(buf);
    //   index += chunkSize;
    //   const progress = new Date().getTime();
    //   console.log('decryptAES progress ' + (start - progress) + 'ms');
    // } while(index < length);
    // var result = decipher.finish();
    // decrypted += decipher.output.getBytes();
    //
    // const finish = new Date().getTime();
    // const finish = new Date().getTime();
    // console.log('decryptAES finished ' + (start - finish) + 'ms');
    // return decrypted;

  }

  private decryptAESUTF8(encrypted: string, iv: string, key: any) {
    let timer = this.timerFactory.start("decryptAESUTF8");

    var ret = "Decoding error";
    try {
      ret = forge.util.decodeUtf8(this.decryptAESSync(encrypted, iv, key));
    } catch (e) {
      console.log(e);
    }
    timer.stop();
    return ret;
  }

  private decryptRSA(encrypted: string) {
    let timer = this.timerFactory.start("decryptRSA");
    var decrypted = this.privateKey.decrypt(forge.util.decode64(encrypted));
    timer.stop();
    return decrypted;
  }

  private encryptRSA(input: any) {
    return this.encryptRSAImpl(input, this.publicKey);
  }

  private encryptRSAImpl(input: any, key: any) {
    var encrypted = key.encrypt(input);
    return forge.util.encode64(encrypted);
  }
  generateSessionKey() {

    let rnd = forge.random.getBytesSync(32);

    let keyEncryptedBase64 = this.encryptRSA(rnd);
    return keyEncryptedBase64;
  }

  signatureBase64(rnd: any) {
    var md = forge.md.sha256.create();
    md.update(rnd);
    var signature = this.privateKey.sign(md);
    return forge.util.encode64(signature);
  }

  signatureBase64Check(rnd: any, signatureBase64: any,publicKeyBase64: string) {
    var md = forge.md.sha256.create();
    md.update(rnd);
    // var signature = this.privateKey.sign(md);
    var publicKey = forge.pki.publicKeyFromPem(publicKeyBase64);
    var b = md.digest().bytes()
    var s = forge.util.decode64(signatureBase64)
    var verified = false
    try {
      verified = publicKey.verify(b, s);

    } catch (e) {
      console.log(e)

    }

    return verified;
  }


  generateSessionKeyValue(userId: string, signerKeyPairUuid: string): SessionKeyValue {

    let rnd = forge.random.getBytesSync(32);

    let keyEncryptedBase64 = this.encryptRSA(rnd);
    let signBase64 = this.signatureBase64(rnd);
    let sessionKeyValue = new SessionKeyValue(userId, keyEncryptedBase64, signBase64, userId, signerKeyPairUuid);
    this.sessionKeys.set("-1",rnd);
    return sessionKeyValue;
  }

  sessionKeyForUser(conversationUuid: string, sessionKeyUuid: string, userId: string, signatureBase64:
    string, signerId: string, signerKeyPairUuid: string, f: (userSessionKey: SessionKeyValue) => void) {

    this.getPublicKey(userId, (publicKey) => {
      this.sessionKeyForUserImpl(conversationUuid, sessionKeyUuid, userId, publicKey, signatureBase64, signerId, signerKeyPairUuid,(sessionKeyValue: SessionKeyValue) => {
        f(sessionKeyValue);
      });
    });
  }


  private sessionKeyForUserImpl(conversationUuid: string, sessionKeyUuid: string, userId: string, publicKey: any, signatureBase64: string, signerId: string, signerKeyPairUuid: string, f:(sessionKeyValue: SessionKeyValue) => void) {
    this.getSessionKey(conversationUuid, sessionKeyUuid, (sessionKeyOpen) => {
      let keyEncryptedBase64 = this.encryptRSAImpl(sessionKeyOpen, publicKey);
      let sessionKeyValue = new SessionKeyValue(userId, keyEncryptedBase64, signatureBase64,signerId, signerKeyPairUuid);
      f(sessionKeyValue);
    })

  }

  private getPublicKey(userId: string, f: (publicKey: any) => void) {
    if(this.publicKeys.has(userId)) {
      f(this.publicKeys.get(userId));
    } else {
      let requestGetUserInfo = new GetUserInfo(userId);
      this.websocket.sendRequest(requestGetUserInfo, (responseHanler) => {
        try{
          let  responseUserInfo = responseHanler as UserInfo
          let publicKey = forge.pki.publicKeyFromPem(responseUserInfo.user.publicKeyBase64);
          this.publicKeys.set(userId, publicKey);
          // this.userNicks.set(userId, responseUserInfo.user.nick);
          f(publicKey);
        } catch (e) {
          console.log(e)
          f("");
        }
      });
    }
  }

  public getNick(userId:string, conversationUuid: string, f:(nick: string) => void) {
      this.userNickQueue.push([userId, conversationUuid, f]);
  }

  destroyPublicKeys() {
    this.publicKeys = new Map();
    this.userNicks = new Map();

  }

  sha256(str: string):string {
    var md = forge.md.sha256.create();
    md.update(str);
    return forge.util.encode64(md.digest().getBytes());
  }

  getFileExt(fileName: string): string {
    if(fileName == null)
      return  "";
    let parts = fileName.split(".")
    return fileName.split(".")[parts.length-1]
  }

  getFileType(fileName: string) {
    if(fileName == null)
      return  null;

    let endFileName= this.getFileExt(fileName);
    if(endFileName == 'wav') {
      return 'wav';
    }
    if(
      endFileName == 'jpeg' ||
      endFileName == 'jpg' ||
      endFileName == 'gif' ||
      endFileName == 'png'
    ) {
      return 'jpeg';
    }
    if (endFileName == '3gp' || endFileName == 'mp4') {
      return '3gp';
    }
    return 'file';
  }

  encryptByMetaDataKey(str: string) {
    return this.encryptByMetaDataKeyImpl(forge.util.createBuffer(str, "utf8").getBytes());
  }
  decryptByMetaDataKey(str: string) {
    if(str == null) {
      return null;
    }
    try {
      return forge.util.decodeUtf8(this.decryptAvatarByMetaDataKeyImpl(forge.util.decode64(str)));
    } catch (e) {
      console.log(e);
      const ret = this.decryptAvatarByMetaDataKeyImpl(forge.util.decode64(str));
      // console.log(ret);
      return ret;
    }
    return this.decryptAvatarByMetaDataKeyImpl(forge.util.decode64(str));
  }

  encryptBase64BySessionKey(str: string,  sessionKeyUuid: string, conversationUuid: string, f:(r: string) => void) {
    this.getSessionKey(conversationUuid, sessionKeyUuid, (sessionKey) => {
      try {
        let encryptedMessage = this.encryptAESUTF8(str, sessionKey, null);
        let ivBase64 = forge.util.encode64(encryptedMessage[0]);
        let bodyEncryptedBase64 = forge.util.encode64(encryptedMessage[1]);
        let res = new DataEncryptedBySessionKey(sessionKeyUuid, conversationUuid, ivBase64, bodyEncryptedBase64);
        f(forge.util.encode64(JSON.stringify(res)));
      } catch (e) {
        console.log("Encryption error");
        f("");
      }
    })
  }

  encryptBySessionKey(str: string,  sessionKeyUuid: string, conversationUuid: string, f:(r: string) => void) {
    this.getSessionKey(conversationUuid, sessionKeyUuid, (sessionKey) => {
      try {
        let encryptedMessage = this.encryptAES(str, sessionKey, null);
        let ivBase64 = forge.util.encode64(encryptedMessage[0]);
        let bodyEncryptedBase64 = forge.util.encode64(encryptedMessage[1]);
        let res = new DataEncryptedBySessionKey(sessionKeyUuid, conversationUuid, ivBase64, bodyEncryptedBase64);
        f(JSON.stringify(res));
      } catch (e) {
        console.log("Encryption error");
        f("");
      }
    })
  }

  decryptBase64BySessionKey(encData: string, f: (str: string) => void) {
    let timer =this.timerFactory.start("decryptBase64BySessionKey");
    let dataEncryptedBySessionKey:DataEncryptedBySessionKey = JSON.parse(forge.util.decode64(encData));

    this.getSessionKey(dataEncryptedBySessionKey.conversationUuid, dataEncryptedBySessionKey.sessionKeyUuid, (sessionKey) => {
      if(sessionKey == null) {
        timer.stop();
        f("Session Key is absent or invalid");
      } else {
        let decryptedBody = this.decryptAESUTF8(dataEncryptedBySessionKey.encryptedBodyBase64, dataEncryptedBySessionKey.ivBase64, sessionKey);
        timer.stop();
        f(decryptedBody);
      }
    })

  }

  extractSessionKeyUuidBase64(encData: string) {
    let dataEncryptedBySessionKey:DataEncryptedBySessionKey = JSON.parse(forge.util.decode64(encData));
    return dataEncryptedBySessionKey.sessionKeyUuid;
  }
  extractSessionKey(encData: string) {
    let dataEncryptedBySessionKey:DataEncryptedBySessionKey = JSON.parse(encData);
    return dataEncryptedBySessionKey.sessionKeyUuid;
  }


}
export class TimerFactory {
  counter = 0;
  start(name: string, details: string = "") {
    this.counter = this.counter + 1;
    return new Timer(name + '_' + this.counter, details);
  }

}
export class Timer {
  begin:any = null;
  constructor(public name: string, public details: string = "") {
    this.begin = new Date().getTime();
    // console.log("Timer: '" + this.name + "' start");
  }

  stop() {
    const end = new Date().getTime();
    // console.log("Timer: '" + this.name + "' - " + (end-this.begin) + " ms");
    if(this.details != null) {
      // console.log("Timer: '" + this.name + "_' - " + this.details);
    }
  }


}

// export declare type ReturnType = (str: string) => void
