import {ConversationDeleted} from "./response";
import {Contact} from "./model";

const RequestUpdateDeviceTokenByApplication = "RequestUpdateDeviceTokenByApplication";
const RequestRegisterApplication = "RequestRegisterApplication";
const RequestConfirmRegistration = "RequestConfirmRegistration";
const RequestAddUserKeys = "RequestAddUserKeys";
const RequestGetUserInfo = "RequestGetUserInfo";
const RequestUpdateUserKeys = "RequestUpdateUserKeys";
const RequestConfirmUpdateUserKeys = "RequestConfirmUpdateUserKeys";
const RequestAuthenticationByApplication = "RequestAuthenticationByApplication";
const RequestGetApplications = "RequestGetApplications";
const RequestGetApplicationsFull = "RequestGetApplicationsFull";
const RequestCreateConversation = "RequestCreateConversation";
const RequestAddMember = "RequestAddMember";
const RequestAddAdmin = "RequestAddAdmin";
const RequestGetMembersList = "RequestGetMembersList";
const RequestGetConversations = "RequestGetConversations";
const RequestGetConversationsFull = "RequestGetConversationsFull";
const RequestGetPublicKeys = "RequestGetPublicKeys";
const RequestNewAddSessionKeys = "RequestAddNewSessionKeys";
const RequestGetUpdatedPublicKeys = "RequestGetUpdatedPublicKeys";
const RequestAddUpdatedSessionKeys = "RequestAddUpdatedSessionKeys";
const RequestGetSessionKey = "RequestGetSessionKey";
const RequestAddMessage = "RequestAddMessage";
const RequestGetMessages = "RequestGetMessages";
const RequestAddFiles = "RequestAddFiles";
const RequestGetFiles = "RequestGetFiles";
const RequestGetRegisteredUsers = "RequestGetRegisteredUsers";
const RequestUpdateUserNick = "RequestUpdateUserNick";
const RequestPing = "RequestPing";
const RequestGetContactList = "RequestGetContactList";
const RequestDeleteConversation = "RequestDeleteConversation";
const RequestLeaveConversation = "RequestLeaveConversation";
const RequestUpdateContactList = "RequestUpdateContactList";
const RequestDeleteApplication = "RequestDeleteApplication";
const RequestMessagesReaded = "RequestMessagesReaded";
const RequestTyping = "RequestTyping";
const RequestRenameConversation = "RequestRenameConversation";
const RequestMarkAllReaded = "RequestMarkAllReaded";
const RequestUpdateContact = "UpdateContact";
const RequestGetNicks = "GetNicks"
const RequestGetUserPublicKey = "RequestGetUserPublicKey"
const RequestGetServerSettings = "RequestGetServerSettings"
const RequestMessageDelivered = "MessageDelivered"
const RequestDeleteUser2 = "RequestDeleteUser2"
const RequestDeleteUserConfirmation2 = "RequestDeleteUserConfirmation2"
const RequestUpdateMemberNick = "UpdateMemberNick"


export interface RequestObject {

}


export class Ping implements RequestObject {
  when: Date = new Date();
  clientVersion: string =  "7.09"
  constructor(when: Date) {
    this.when = when;
  }
}

export class UpdateMemberNick implements RequestObject {
  conversationUuid: string = "";
  userId: string = "";
  nickEncrypted: string = "";

  constructor(conversationUuid: string, userId: string, nickEncrypted: string) {
    this.conversationUuid = conversationUuid;
    this.userId = userId;
    this.nickEncrypted = nickEncrypted;
  }
}


export class RegisterApplication implements RequestObject {
  uuid: string = "";
  applicationType: string = "";
  userId: string = "";
  osVersion: string = "";
  vendor: string = "";
  model: string = "";
  androidSmsToken: string = "";
  openUserId: string = "";

  constructor(uuid: string, applicationType: string, userId: string, openUserId: string) {
    this.uuid = uuid;
    this.applicationType = applicationType;
    this.userId = userId;
    this.openUserId = openUserId;
  }
}

export class ConfirmRegistration implements RequestObject {
  uuid: string = "";
  smsCode: string = "";
  userId: string = "";


  constructor(uuid: string, smsCode: string, userId: string) {
    this.uuid = uuid;
    this.smsCode = smsCode;
    this.userId = userId;
  }
}


export class DeleteUser2 implements RequestObject {
  userId: string = "";
  openUserId: string = "";

  constructor(userId: string, openUserId: string) {
    this.userId = userId;
    this.openUserId = openUserId;
  }
}

export class DeleteUserConfirmation2 implements RequestObject {
  userId: string = "";
  code: string = "";
  constructor(userId: string, code: string) {
    this.userId = userId;
    this.code = code;
  }
}

export class MessageDelivered  implements RequestObject {
    conversationUuid: string = "";
    messageUuid: string = "";

    constructor(conversationUuid: string, messageUuid: string) {
      this.conversationUuid = conversationUuid;
      this.messageUuid = messageUuid;
    }
  }

  export class GetUserPublicKey  implements RequestObject {
  userId: string = "";
  keyPairUuid: string = "";

  constructor(userId: string, keyPairUuid: string) {
    this.userId = userId;
    this.keyPairUuid = keyPairUuid;
  }
}

export class GetServerSettings implements RequestObject {

}

export class Typing {
  conversationUuid: string = "";

  constructor(conversationUuid: string) {
    this.conversationUuid = conversationUuid;
  }
}



  export class AuthenticationByApplication implements RequestObject {
  uuid: string = "";
  userId: string = "";


    constructor(uuid: string, userId: string) {
      this.uuid = uuid;
      this.userId = userId;
    }
  }

export class GetUserInfo implements RequestObject {
  userId: string = "";


  constructor(userId: string) {
    this.userId = userId;
  }
}

export class GetConversations implements RequestObject {
  userId: string | null = null;


}
export class GetConversationsFull implements RequestObject {
  userId: string | null = null;


}

export class GetSessionKey implements RequestObject {
  conversationUuid: string = "";
  sessionKeyUuid: string = "";


  constructor(conversationUuid: string, sessionKeyUuid: string) {
    this.conversationUuid = conversationUuid;
    this.sessionKeyUuid = sessionKeyUuid;
  }
}

export class AddMessage implements RequestObject {
  messageUuid: string = "";
  conversationUuid: string = "";
  sessionKeyUuid: string = "";
  ivBase64: string = "";
  bodyEncryptedBase64: string = "";
  hasFiles: boolean = false;


  constructor(messageUuid: string, conversationUuid: string, sessionKeyUuid: string, ivBase64: string, bodyEncryptedBase64: string, hasFiles: boolean) {
    this.messageUuid = messageUuid;
    this.conversationUuid = conversationUuid;
    this.sessionKeyUuid = sessionKeyUuid;
    this.ivBase64 = ivBase64;
    this.bodyEncryptedBase64 = bodyEncryptedBase64;
    this.hasFiles = hasFiles;
  }
}

export class GetMessages implements RequestObject {
  conversationUuid: string = "";
  fromMessageUuid: string | null = null;

  size: number = 10;

  constructor(conversationUuid: string, fromMessageUuid: string | null) {
    this.conversationUuid = conversationUuid;
    this.fromMessageUuid = fromMessageUuid;
  }
}


export class AddUserKeys implements RequestObject {
  publicKeyBase64: string = "";
  privateKeyEncryptedBase64: string = "";
  metaDataKeyEncryptedBase64: string = "";
  nick: string = "";


  constructor(publicKeyBase64: string, privateKeyEncryptedBase64: string, metaDataKeyEncryptedBase64: string, nick: string) {
    this.publicKeyBase64 = publicKeyBase64;
    this.privateKeyEncryptedBase64 = privateKeyEncryptedBase64;
    this.metaDataKeyEncryptedBase64 = metaDataKeyEncryptedBase64;
  }
}

export class SessionKeyValue implements RequestObject {
  userId: string = "";
  keyEncryptedBase64: string = "";
  signatureBase64: string = "";
  signerUserId: string = "";
  signerKeyPairUuid: string = "";


  constructor(userId: string, keyEncryptedBase64: string, signatureBase64: string, signerUserId: string, signerKeyPairUuid: string) {
    this.userId = userId;
    this.keyEncryptedBase64 = keyEncryptedBase64;
    this.signatureBase64 = signatureBase64;
    this.signerUserId = signerUserId;
    this.signerKeyPairUuid = signerKeyPairUuid;
  }
}


export class CreateConversation implements RequestObject {
  isDialog: boolean = false;
  sessionKeyValue: SessionKeyValue = new SessionKeyValue("", "", "", "", "");
  title: string | null = "";

  constructor(isDialog: boolean, sessionKeyValue: SessionKeyValue, title: string | null) {
    this.isDialog = isDialog;
    this.sessionKeyValue = sessionKeyValue;
    this.title = title;
  }
}


export class AddMember implements RequestObject {
  conversationUuid: string = "";
  sessionKeyUuid: string = "";
  sessionKeyValue: SessionKeyValue = new SessionKeyValue("", "", "", "", "");

  constructor(conversationUuid: string, sessionKeyUuid: string, sessionKeyValue: SessionKeyValue) {
    this.conversationUuid = conversationUuid;
    this.sessionKeyUuid = sessionKeyUuid;
    this.sessionKeyValue = sessionKeyValue;
  }
}

export class AddAdmin implements RequestObject {
  conversationUuid: string = "";
  sessionKeyUuid: string = "";
  sessionKeyValue: SessionKeyValue =new SessionKeyValue("", "", "", "", "");

  constructor(conversationUuid: string, sessionKeyUuid: string, sessionKeyValue: SessionKeyValue) {
    this.conversationUuid = conversationUuid;
    this.sessionKeyUuid = sessionKeyUuid;
    this.sessionKeyValue = sessionKeyValue;
  }
}

export class UpdateContact implements RequestObject {
    userId: string = "";
    contact: Contact = new Contact("", "", "", "");

  constructor(userId: string, contact: Contact) {
    this.userId = userId;
    this.contact = contact;
  }
}


export class GetNicks implements RequestObject {
  conversationUuid: string = "";


  constructor(conversationUuid: string) {
    this.conversationUuid = conversationUuid;
  }
}


export class GetMembersList implements RequestObject {
  conversationUuid: string = "";


  constructor(conversationUuid: string) {
    this.conversationUuid = conversationUuid;
  }
}

export class GetContactList implements RequestObject {
  userId: string = "";


  constructor(userId: string) {
    this.userId = userId;
  }
}

export class AddNewSessionKeys implements RequestObject {
  conversationUuid: string = "";
  sessionKeys: SessionKeyValue[] = [];

  constructor(conversationUuid: string, sessionKeys: SessionKeyValue[]) {
    this.conversationUuid = conversationUuid;
    this.sessionKeys = sessionKeys;
  }
}


export class UpdateUserKeys implements RequestObject {
  publicKeyBase64: string = "";
  privateKeyEncryptedBase64: string = "";

  constructor(publicKeyBase64: string, privateKeyEncryptedBase64: string) {
    this.publicKeyBase64 = publicKeyBase64;
    this.privateKeyEncryptedBase64 = privateKeyEncryptedBase64;
  }
}

export class ConfirmUpdateUserKeys implements RequestObject {
  smsCode: string = "";

  constructor(smsCode: string) {
    this.smsCode = smsCode;
  }
}

export class GetUpdatedPublicKeys implements RequestObject {
  conversationUuid: string = "";


  constructor(conversationUuid: string) {
    this.conversationUuid = conversationUuid;
  }
}

export class DeleteConversation implements RequestObject {
  conversationUuid: string = "";


  constructor(conversationUuid: string) {
    this.conversationUuid = conversationUuid;
  }
}

export class LeaveConversation implements RequestObject {
  conversationUuid: string = "";


  constructor(conversationUuid: string) {
    this.conversationUuid = conversationUuid;
  }
}


export class UpdateContactList implements RequestObject {
  contactList: String[] = [];
  constructor(contactList: String[]) {
    this.contactList = contactList;
  }
}


export class GetRegisteredUsers implements RequestObject {
  checkUserList: string[] = [];


}

export class UpdateUserNick implements RequestObject {
  nick: string = "";


  constructor(nick: string) {
    this.nick = nick;
  }
}



export class DeleteApplication implements RequestObject {
  uuid: string = "";


  constructor(uuid: string) {
    this.uuid = uuid;
  }
}

export class GetApplications implements RequestObject {
  confirmed: boolean = true;

  constructor(confirmed: boolean) {
    this.confirmed = confirmed;
  }
}

export class GetApplicationsFull implements RequestObject {
  confirmed: boolean = true;

  constructor(confirmed: boolean) {
    this.confirmed = confirmed;
  }
}


export class GetFiles implements RequestObject {
  conversationUuid: string = "";
  messageUuid: string = "";

  constructor(conversationUuid: string, messageUuid: string) {
    this.conversationUuid = conversationUuid;
    this.messageUuid = messageUuid;
  }
}

export class MessagesReaded implements RequestObject {
  conversationUuid: string = "";
   messageUuids:string[] = [];

  constructor(conversationUuid: string, messageUuids: string[]) {
    this.conversationUuid = conversationUuid;
    this.messageUuids = messageUuids;
  }
}

export class MarkAllReaded implements RequestObject {
  conversationUuid: string = "";

  constructor(conversationUuid: string) {
    this.conversationUuid = conversationUuid;
  }
}

export class RenameConversation implements RequestObject {
  conversationUuid: string = "";
  title:string = "";

  constructor(conversationUuid: string, title: string) {
    this.conversationUuid = conversationUuid;
    this.title = title;
  }
}


export class Request {

  static toJson(object: RequestObject, requestId: number): string {

    Date.prototype.toJSON = function ():any {
      return this.getTime();
    };

    switch (object.constructor) {
      case Ping: {
        return RequestPing + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case RegisterApplication: {
        return RequestRegisterApplication + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case ConfirmRegistration: {
        return RequestConfirmRegistration + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetUpdatedPublicKeys: {
        return RequestGetUpdatedPublicKeys + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case AuthenticationByApplication: {
        return RequestAuthenticationByApplication + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetFiles: {
        return RequestGetFiles + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case DeleteApplication: {
        return RequestDeleteApplication + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetApplications: {
        return RequestGetApplications + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetApplicationsFull: {
        return RequestGetApplicationsFull + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetUserInfo: {
        return RequestGetUserInfo + ":" + requestId + "\n" + JSON.stringify(object);
      }
      case MessagesReaded: {
        return RequestMessagesReaded + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetConversations: {
        return RequestGetConversations + ":" + requestId + "\n" + JSON.stringify(object);
      }
      case GetConversationsFull: {
        return RequestGetConversationsFull + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case MessageDelivered: {
        return RequestMessageDelivered + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case MarkAllReaded: {
        return RequestMarkAllReaded + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetSessionKey: {
        return RequestGetSessionKey + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case UpdateUserNick: {
        return RequestUpdateUserNick + ":" + requestId + "\n" + JSON.stringify(object);
      }


      case AddMessage: {
        return RequestAddMessage + ":" + requestId + "\n" + JSON.stringify(object);
      }


      case GetMessages: {
        return RequestGetMessages + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case AddUserKeys: {
        return RequestAddUserKeys + ":" + requestId + "\n" + JSON.stringify(object);
      }


      case CreateConversation: {
        return RequestCreateConversation + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case AddMember: {
        return RequestAddMember + ":" + requestId + "\n" + JSON.stringify(object);
      }


      case UpdateContactList: {
        return RequestUpdateContactList + ":" + requestId + "\n" + JSON.stringify(object);
      }


      case GetRegisteredUsers: {
        return RequestGetRegisteredUsers + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case AddAdmin: {
        return RequestAddAdmin + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case Typing: {
        return RequestTyping + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetMembersList: {
        return RequestGetMembersList + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetContactList: {
        return RequestGetContactList + ":" + requestId + "\n" + JSON.stringify(object);
      }


      case AddNewSessionKeys: {
        return RequestNewAddSessionKeys + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case UpdateUserKeys: {
        return RequestUpdateUserKeys + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case ConfirmUpdateUserKeys: {
        return RequestConfirmUpdateUserKeys + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case UpdateMemberNick:  {
        return RequestUpdateMemberNick + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case DeleteConversation: {
        return RequestDeleteConversation + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case LeaveConversation: {
        return RequestLeaveConversation + ":" + requestId + "\n" + JSON.stringify(object);
      }


      case RenameConversation: {
        return RequestRenameConversation + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case UpdateContact: {
        return RequestUpdateContact + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetNicks: {
        return RequestGetNicks + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetUserPublicKey: {
        return RequestGetUserPublicKey + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case GetServerSettings: {
        return RequestGetServerSettings + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case DeleteUser2: {
        return RequestDeleteUser2 + ":" + requestId + "\n" + JSON.stringify(object);
      }

      case DeleteUserConfirmation2: {
        return RequestDeleteUserConfirmation2 + ":" + requestId + "\n" + JSON.stringify(object);
      }

      default: {
        return "cant create json";
      }

    }
  }

}
