<h1 mat-dialog-title>Jetico Messenger FAQ</h1>
<div mat-dialog-content *ngIf="!hide">
  <h2><a name="about">What is Jetico Messenger?</a></h2>

  <p>
    Jetico Messenger is a simple messaging app with a focus on security. It has most of the features expected from a messenger but adds an extra layer of security by encrypting everything on your device.
    Currently, Jetico Messenger is in the early stages of development. More features are expected to come in the following years.
  </p>
  <h2><a name="auditory">Who is it for?</a></h2>
  <p>
    Jetico Messenger is intended for users who value their privacy and the security of communication over anything. Terrified of the idea that someone is always watching you? Jetico Messenger is for you!
  </p>

  <h2><a name="difference">How is it different from WhatsApp\Telegram?</a></h2>
  <p>
    Jetico Messenger is not a social media platform, it is a heavily encrypted tool for communication. Even data on your phone is encrypted. No one will be able to access your information even if they get their hands on your device along with a SIM card.
  </p>
  <h2><a name="availability">Is it available on my device?</a></h2>
  <p>
    Currently, the mobile Jetico Messenger app is available only for Android devices. But you should be able to access Jetico Messenger via the Web interface from any device.
  </p>
  <h2><a name="devs">Who are the people behind Jetico Messenger</a></h2>
  <p>
    Jetico is a team of data security specialists with over 28 years of experience. Now we are trying to apply our extensive knowledge to the field of secure communication.
  </p>
  <h2><a name="location">Where is Jetico Messenger based?</a></h2>
  <p>
    Espoo, Finland.
  </p>
  <h2><a name="money">How do you make money?</a></h2>
  <p>
    We will release the Enterprise version of Jetico Messenger in the next year. Companies will be able to host their own Jetico Messengers servers with additional functionality.
  </p>
  <h2><a name="privacy">What are your thoughts on internet privacy?</a></h2>
  <p>
    We are not obliged to provide any information to government structures and we are famous for having no backdoors. There are no mechanisms that will let any third-party to access your data.
  </p>
  <h2><a name="gdpr">What about GDPR?</a></h2>
  <p>
    We are fully compliant with GDPR.
  </p>
  <h2><a name="takedown">Do you process takedown requests?</a></h2>
  <p>
    We have a moderation system based on reports.
  </p>
  <h2><a name="requests">Do you process data requests?</a></h2>
  <p>
    We don't have access to the encryption keys of users. There is no way for us to process any data requests.
  </p>
  <h2><a name="basics">Jetico Messenger Basics</a></h2>
  <h2><a name="recipient">Who can I message?</a></h2>
  <p>
    People from your contacts that are registered in Jetico Messenger.
  </p>
  <h2><a name="sender">Who can message me?</a></h2>
  <p>
    Anyone who has your number and verified you.
  </p>
  <h2><a name="invitation">Inviting friends</a></h2>
  <p>
    Use the option in the sandwich menu on the left.
  </p>
  <h2><a name="checkmarks">What do the check marks mean in Jetico Messenger?</a></h2>
  <p>
    1 grey - sent.
    2 grey - delivered.
    2 green - read.
  </p>
  <h2><a name="deletion">Can I delete my messages?</a></h2>
  <p>
    Yes. (Currently, this functionality is limited to the android application)
  </p>
  <h2><a name="calls">Voice, Video and Group calls</a></h2>
  <p>
    All calls are still in development.
  </p>
  <h2><a name="groups">Groups</a></h2>
  <h2><a name="creating">Creating a group</a></h2>
  <p>
    Click on the pencil button at the bottom of the chat-list screen and select the “Create group” option.
  </p>
  <h2><a name="adding">Adding members and using Invite Links</a></h2>
  <p>
    You can invite other users in the chat from the top right menu. Invite Links are still in development.
  </p>
  <h2><a name="security">Security</a></h2>
  <h2><a name="howsec">How secure is Jetico Messenger?</a></h2>
  <p>
    All data is shared and stored in an encrypted form.
  </p>
  <h2><a name="encryption">How do you encrypt data?</a></h2>
  <p>
    Jetico Messenger utilizes the AES encryption algorithm with the largest possible 256-bit key size with CTR encryption mode - the strongest encryption. RSA 4096-bit encryption is used for storing, sharing, and verification. Everything is stored in an encrypted form. Decrypted keys are only in RAM and decrypted messages are only on your screen.
  </p>
  <h2><a name="trust">Why should I trust you?</a></h2>
  <p>
    Our reputation speaks for itself. Jetico encryption solutions are known and valued for not including backdoors or related vulnerabilities. The only one who can access your encrypted data is you!
  </p>
  <h2><a name="secure">Do I need to trust Jetico Messenger for it to be secure?</a></h2>
  <p>
    The verification mechanic is specifically designed to provide users with an option to check the security of communication manually.
  </p>
  <h2><a name="hacker">What if my hacker friend doubts you?</a></h2>
  <p>
    All data is encrypted and there is nothing your hacker friends can do about it.
  </p>
  <h2><a name="protection">Can Jetico Messenger protect me against everything?</a></h2>
  <p>
    As an example, Telegram clearly states that they can't protect you from your mother or IT department but we can because we encrypt everything on the device.
  </p>
  <h2><a name="session">Why I should regenerate the session key?</a></h2>
  <p>
    Because it is important in terms of security to regenerate the session key as often as you can. Key will be encrypted with public keys of participants of the chat
  </p>
  <h2><a name="undecrypted">Why do I see undecrypted messages and undecrypted titles?</a></h2>
  <p>
    Due to our high level of security, if one party loses access to the account, it is impossible to decrypt the messages.
  </p>
  <h2><a name="account">Your Account</a></h2>
  <h2><a name="number">Who can see my phone number?</a></h2>
  <p>
    No one can see you in Jetico Messenger unless they got your number in the first place.
  </p>
  <h2><a name="new">I have a new phone number, what do I do?</a></h2>
  <p>
    New number - new account. You need to be able to receive SMS to the old phone number in order to activate the account on a new device.
  </p>
  <h2><a name="logout">Log out of Jetico Messenger</a></h2>
  <p>
    Log out is in the top-right sandwich menu. We also have an automatic logout option for security purposes. You can disable or adjust it in Security settings.
    There is also a panic button at the top-right corner of the screen.
  </p>
  <h2><a name="change">Change your phone number</a></h2>
  <p>
    New number - a new account. Changing is unavailable at the moment.
  </p>
  <h2><a name="del">Delete your Jetico Messenger account</a></h2>
  <p>
    You can delete your account in the Security setting or set Account timeout there.
  </p>
  <h2><a name="self">How does account self-destruction work?</a></h2>
  <p>
    Your account will no longer be accessible. Your copies of the chats will be deleted from the server. You can create a new account with the same number.
  </p>
  <h2><a name="stolen">My phone was stolen. What do I do?</a></h2>
  <p>
    You can restore your SIM card and access your account. Criminals won’t be able to access your data without a password. They can only reset the account.
  </p>
  <h2><a name="deep">Deeper Questions</a></h2>
  <h2><a name="server">Can I use my own server?</a></h2>
  <p>
    You can purchase Jetico Messenger Enterprise and set up your own server with additional functionality.
  </p>
  <h2><a name="api">Can I use the Jetico Messenger API?</a></h2>
  <p>
    Not at the moment.
  </p>
  <h2><a name="policy">Do you have a Privacy Policy?</a></h2>
  <p>
    <a href="https://www.jetico.com/privacy-policy-android-applications">Privacy Policy</a>
  </p>
  <h2><a name="translation">Can I translate Jetico Messenger?</a></h2>
  <p>
    You can contact us at <a href="mailto:support@jetico.com">support@jetico.com</a> to discuss this matter.
  </p>
  <h2><a name="help">Can I help?</a></h2>
  <p>
    Send your suggestions to <a href="mailto:support@jetico.com">support@jetico.com</a>.
  </p>
  <br><br>


  <br><br>


</div>

<div mat-dialog-actions align="center">
  <button mat-button [mat-dialog-close]="false">Close</button>
</div>
